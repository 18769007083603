<!-- eslint-disable -->
<template>
  <div class="page_box">
    <sb-headline :title="'唯一线索'"></sb-headline>
    <my-table ref="list" :data="listData" @page="pageHandler" :listLoading="listLoading">
      <!-- 表格数据搜索 -->
      <template #header="params">
        <div class="search_box">
          <el-tabs v-model="selectTab" @tab-click="pageHandler(params, 1)">
            <el-tab-pane
              v-for="(item, index) in tabList"
              :key="index"
              :label="item.stageName+'('+item.count+')'"
              :name="item.stage+''"
            ></el-tab-pane>
          </el-tabs>
          <div style="display: flex;justify-content: end">
            <el-button type="text" @click="operBtnHandle">{{operBtnText}}<i :class="operBtnClas"></i></el-button>
          </div>
          <el-form :inline="true" :model="searchData" label-position="left">
            <el-form-item>
              <el-input v-model="searchData.clueId" placeholder="唯一线索id"></el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.clueStatus" placeholder="线索状态">
                <el-option label="新建" value="0"></el-option>
                <el-option label="已派发" value="1"></el-option>
                <el-option label="已分配" value="2"></el-option>
                <el-option label="已邀约" value="3"></el-option>
                <el-option label="已到店" value="4"></el-option>
                <el-option label="已试驾" value="5"></el-option>
                <el-option label="已下订" value="6"></el-option>
                <el-option label="战败" value="8"></el-option>
                <el-option label="已交车" value="9"></el-option>
                <el-option label="已转移" value="11"></el-option>
                <el-option label="无效" value="21"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model="searchData.customerName" placeholder="客户名称"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="searchData.customerMobile" placeholder="手机号"></el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.clueLevel" placeholder="意向级别">
                <el-option label="O" value="1"></el-option>
                <el-option label="H" value="2"></el-option>
                <el-option label="A" value="2"></el-option>
                <el-option label="B" value="4"></el-option>
                <el-option label="C" value="5"></el-option>
                <el-option label="D" value="6"></el-option>
                <el-option label="F" value="7"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-input v-model="searchData.seller" placeholder="销售顾问"></el-input>
            </el-form-item>

            <el-form-item>
              <el-select v-model="searchData.firstClueLevel" placeholder="首次意向级别">
                <el-option label="O" value="1"></el-option>
                <el-option label="H" value="2"></el-option>
                <el-option label="A" value="2"></el-option>
                <el-option label="B" value="4"></el-option>
                <el-option label="C" value="5"></el-option>
                <el-option label="D" value="6"></el-option>
                <el-option label="F" value="7"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input v-model="searchData.vin" placeholder="VIN"></el-input>
            </el-form-item>
            <el-form-item>
              <el-input v-model="searchData.productName" placeholder="意向产品"></el-input>
            </el-form-item>
            <el-form-item>
              <el-select v-model="searchData.ownerType" placeholder="派发主体">
                <el-option label="主机厂" value="0"></el-option>
                <el-option label="经销商" value="1"></el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-input v-model="searchData.province" placeholder="省份"></el-input>
            </el-form-item>

            <el-form-item>
              <el-input v-model="searchData.city" placeholder="城市"></el-input>
            </el-form-item>
            <div v-if="operBtnFlag">
              <el-form-item label="创建日期" label-width="100px">
                <el-date-picker
                  v-model="searchData.createTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>

              <el-form-item label="最近跟进日期" label-width="100px">
                <el-date-picker
                  v-model="searchData.lastFollowTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>

              <el-form-item label="派发日期" label-width="100px">
                <el-date-picker
                  v-model="searchData.issueTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>

              <el-form-item label="分配日期" label-width="100px">
                <el-date-picker
                  v-model="searchData.allocateTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="下订日期" label-width="100px">
                <el-date-picker
                  v-model="searchData.depositTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="首次到店日期" label-width="100px">
                <el-date-picker
                  v-model="searchData.firstArrivalTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>


              <el-form-item label="最近试驾日期" label-width="100px">
                <el-date-picker
                  v-model="searchData.firstDriveTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="最近战败日期" label-width="100px">
                <el-date-picker
                  v-model="searchData.lostTime"
                  type="daterange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  value-format="yyyy-MM-dd"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </el-form>
          <el-row :gutter="10">
            <el-col :span="4" :offset="20">
              <el-button @click="resetSearchData">重置</el-button>
              <el-button @click="pageHandler(params, 1)" type="primary">查询</el-button>
            </el-col>
          </el-row>
        </div>
      </template>

      <!-- 表格顶部操作 -->
      <template #navBar>
        <div class="table_top_box">
          <div>共{{ customerTotal }}条线索</div>
          <el-button type="text" icon="el-icon-refresh-left" @click="updateUserFn">更新数据</el-button>
        </div>
      </template>

      <!-- 表格本体 -->
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column label="客户姓名" fixed="left" prop="" :min-width="230">
        <template slot-scope="scope">
          <div class="table_box_custom" @click="openUserInfoFn(scope.row)">
            <img class="default_head" src="https://taian-oss.dasmaster.com/wechat/default_head_admin_table.png" />
            <div class="user_name">
              <div>{{scope.row.customerName}}</div>
              <div>--</div>
            </div>
          </div>
        </template>
      </el-table-column>

      <span v-for="(item, index) in columnData" :key="index">
        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-if="item.prop == 'clueLevel'">
          <template slot-scope="scope">
            {{ scope.row.clueLevel | clueLevelFormatter}}
          </template>
        </el-table-column>
        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-else-if="item.prop == 'ownerType'">
          <template slot-scope="scope">
            {{ scope.row.ownerType | ownerTypeFormatter}}
          </template>
        </el-table-column>
      <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-else-if="item.prop == 'taskCallOverdue'">
          <template slot-scope="scope">
            {{ scope.row.taskCallOverdue | taskCallOverdueFormatter}}
          </template>
        </el-table-column>
        <el-table-column :label="item.label" :prop="item.prop" :min-width="item.width" v-else-if="item.prop == 'clueStatus'">
          <template slot-scope="scope">
            {{ scope.row.clueStatus | clueStatusFormatter}}
          </template>
        </el-table-column>

        <el-table-column v-else :label="item.label" :prop="item.prop" :min-width="item.width">
          <template slot-scope="scope">
            <div v-if="scope.row[item.prop] || scope.row[item.prop] === 0">{{ scope.row[item.prop] }}</div>
            <div v-else>--</div>
          </template>
        </el-table-column>

      </span>
    </my-table>

    <el-drawer :visible.sync="userInfoDrawer" :with-header="false" size="70%">
      <el-container class="container_box">
        <!-- 头 -->
        <el-header class="container_box_head" height="auto">
          <div style="font-size: 16px;font-weight: 500;line-height: 40px;">意向信息</div>
          <el-row>
            <el-col :span="8">
              <span class="label">线索状态：</span>
              <span class="value">{{userDetailData.clueStatus|clueStatusFormatter}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">意向车型：</span>
              <span class="value">{{emptyToLine(userDetailData.productName)}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">意向级别：</span>
              <span class="value">{{userDetailData.clueLevel | clueLevelFormatter}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">首次意向级别：</span>
              <span class="value">{{userDetailData.firstClueLevel | clueLevelFormatter}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">派发主体：</span>
              <span class="value">{{userDetailData.ownerType | ownerTypeFormatter}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">一级来源：</span>
              <span class="value">{{emptyToLine(userDetailData.firstChannelName)}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">二级来源：</span>
              <span class="value">{{emptyToLine(userDetailData.secondChannelName)}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">三级来源：</span>
              <span class="value">{{emptyToLine(userDetailData.threeChannelName)}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">活动名称：</span>
              <span class="value">{{emptyToLine(userDetailData.activityName)}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">创建时间：</span>
              <span class="value">{{emptyToLine(userDetailData.ctime)}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">派发时间：</span>
              <span class="value">{{emptyToLine(userDetailData.issueTime)}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">分配时间：</span>
              <span class="value">{{emptyToLine(userDetailData.allocateTime)}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">下订时间：</span>
              <span class="value">{{emptyToLine(userDetailData.depositTime)}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">交车时间：</span>
              <span class="value">{{emptyToLine(userDetailData.deliveryTime)}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">下订车型：</span>
              <span class="value">{{emptyToLine(userDetailData.depositProductName)}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">交车车型：</span>
              <span class="value">{{emptyToLine(userDetailData.deliveryProductName)}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">下订金额：</span>
              <span class="value">{{emptyToLine(userDetailData.depositAmount)}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">VIN码：</span>
              <span class="value">{{emptyToLine(userDetailData.vin)}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">计划交车日期：</span>
              <span class="value">{{emptyToLine(userDetailData.preDeliveryTime)}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">最近跟进时间：</span>
              <span class="value">{{emptyToLine(userDetailData.lastFollowTime)}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">战败时间：</span>
              <span class="value">{{emptyToLine(userDetailData.lostTime)}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">首次意向车型：</span>
              <span class="value">{{emptyToLine(userDetailData.firstProductName)}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">未交车原因：</span>
              <span class="value">{{emptyToLine(userDetailData.unDeliveryReason)}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">购车关注点：</span>
              <span class="value">{{ emptyToLine(userDetailData.carFocus) }}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">下次跟进日期：</span>
              <span class="value">{{emptyToLine(userDetailData.nextFollowTime)}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">邀约次数：</span>
              <span class="value">{{emptyToLine(userDetailData.invitationTimes)}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">到店次数：</span>
              <span class="value">{{emptyToLine(userDetailData.storeTimes)}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">试驾次数：</span>
              <span class="value">{{emptyToLine(userDetailData.driveTimes)}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">战败激活时间：</span>
              <span class="value">{{emptyToLine(userDetailData.defeatActivationTime)}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">电商订单id：</span>
              <span class="value">{{emptyToLine(userDetailData.orderId)}}</span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="8">
              <span class="label">备注：</span>
              <span class="value">{{emptyToLine(userDetailData.remark)}}</span>
            </el-col>
            <el-col :span="8">
              <span class="label">增换购：</span>
              <span class="value">{{emptyToLine(userDetailData.addExchangeBuy)}}</span>
            </el-col>
            <el-col :span="8" style="display: flex;align-items: center;">
              <span class="label">充电需求：</span>
              <span class="value">{{emptyToLine(userDetailData.chargeDemand)}}</span>
            </el-col>
          </el-row>

        </el-header>
        <el-container>
          <el-aside class="container_box_aside" width="400px">
            <div class="container_box_aside_head">
              <i class="el-icon-user-solid"></i>
              <div class="container_box_aside_title">
                <div>基础信息</div>
              </div>
            </div>
            <div v-for="(item,index) in userInfoLisy" :key="index" class="container_box_aside_item">
              <div>{{ item.label }}</div>
              <div>{{ userDetailData[item.key] || '--' }}</div>
            </div>
          </el-aside>
          <el-main class="container_box_main">
            <el-tabs class="container_box_main_tabs" v-model="selectMainTab" type="card" @tab-click="maintabHandleClick">
              <el-tab-pane label="客户旅程" name="1"></el-tab-pane>
            </el-tabs>
            <div class="container_box_main_content" v-if="selectMainTab==1">
            </div>
            <!-- 客户旅程 -->

            <div class="client_itinerary" @scroll="clientItineraryScroll" v-if="selectMainTab==1" v-loading="detailListLoading">
              <span v-if="Object.keys(clientItineraryList).length>0">
                <el-timeline v-for="(item, key) in clientItineraryList" :key="key">
<!--                  v-for="(ele,index) in item" :key="index"-->
                  <el-timeline-item :timestamp="index==0?key:''"  placement="top">
                    <div slot="dot" :class="index==0?'client_itinerary_dot client_itinerary_dot_first':'client_itinerary_dot'">
                      <img class="client_itinerary_dot_icon" :src="item.icon" alt="">
                      <div>{{item.happenTime}}</div>
                      <div v-if="index==0" class="client_itinerary_dot_circle">
                        <div class="circle"></div>
                      </div>
                    </div>
                    <el-card style="width: 90%;">
                      <div class="client_itinerary_title">
                        <div>{{ item.wayDesc }}</div>
<!--                        <div v-if="ele.title">{{ ele.title }}</div>-->
                      </div>
                      <div class="client_itinerary_dot_html" v-html="resetTemplateFn(item)"></div>
                      <div class="client_itinerary_dot_operator">
                        <img :src="item.operatorAvatar||'https://taian-oss.dasmaster.com/wechat/default_head_admin_table.png'" alt="">
                        <div>{{ item.operator }}</div>
                        <div>操作</div>
                      </div>
                    </el-card>
                  </el-timeline-item>
                </el-timeline>
              </span>
              <el-empty v-if="Object.keys(clientItineraryList).length<1&&!detailListLoading" :image-size="200"></el-empty>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </el-drawer>
  </div>
</template>

<script>
/* eslint-disable */
import Template from "@/views/dashboard/template.vue";
import {
  emptyToLine,
} from "@/utils/common";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { Template },
  filters:{
    clueLevelFormatter(clueLevel){
      switch (clueLevel){
        case 1:
          return 'O'
        case 2:
          return 'H'
        case 3:
          return 'A'
        case 4:
          return 'B'
        case 5:
          return 'C'
        case 6:
          return 'D'
        case 7:
          return 'F'
      }
      return '--'
    },
    ownerTypeFormatter(ownerType){
      switch (ownerType){
        case 0:
          return '主机厂'
        case 1:
          return '经销商'
      }
      return '--'
    },
    taskCallOverdueFormatter(taskCallOverdue){
      switch (taskCallOverdue){
        case 0:
          return '未逾期'
        case 1:
          return '已逾期'
      }
      return '--'
    },
    clueStatusFormatter(clueStatus){
      switch (clueStatus){
        case 0:
          return '新建'
        case 1:
          return '已派发'
        case 2:
          return '已分配'
        case 3:
          return '已邀约'
        case 4:
          return '已到店'
        case 5:
          return '已试驾'
        case 6:
          return '已下订'
        case 8:
          return '战败'
        case 9:
          return '已交车'
        case 11:
          return '已转移'
        case 21:
          return '无效'
      }
      return '--'
    }

  },
  data() {
    //这里存放数据
    return {
      listData: [], //列表数据
      listLoading: false, //列表loading
      detailListLoading: false, //详情列表loading
      tabList: [],  //标签数据
      selectTab: "1", //标签已选数据
      selectMainTab: "1", //客户信息标签选择
      groupBtnData: "0", //旅程自定义tabs当前选中
      operBtnText: '展开',
      operBtnClas: 'el-icon-arrow-down el-icon--down',
      operBtnFlag : false,
      // 搜索数据
      searchData: {
        clueId: null,
        customerName: null,
        customerMobile: null,
        seller:null,
        vin:null,
        clueLevel: null,
        clueStatus: null,
        ownerType: null,
        city: null,
        province: null,
        createTime:null,
        firstClueLevel: null,
        firstChannelName:null,
        secondChannelName: null,
        threeChannelName: null,
        productName:null,
        lastFollowTime:null,
        issueTime: null,
        allocateTime:null,
        depositTime:null,
        firstArrivalTime: null,
        lostTime: null,
        firstDriveTime: null
      },
      customerTotal: '',      //客户数量
      searchKey: "customerName", //下拉input所选Key
      // 表格column数据
      columnData: [
        { label: "客户名称", width: 100, prop: "customerName" },
        { label: "手机号", width: 120, prop: "customerMobile" },
        { label: "线索状态", width: 120, prop: "clueStatus" },
        { label: "意向车型", width: 100, prop: "productName" },
        { label: "派发主体", width: 100, prop: "ownerType" },
        { label: "意向级别", width: 120, prop: "clueLevel" },
        { label: "一级来源", width: 180, prop: "firstChannelName" },
        { label: "二级来源", width: 180, prop: "secondChannelName" },
        { label: "三级来源", width: 180, prop: "threeChannelName" },
        { label: "销售顾问", width: 180, prop: "seller" },
        { label: "派发时间", width: 150, prop: "issueTime" },
        { label: "分配时间", width: 180, prop: "allocateTime" },
        { label: "是否首呼逾期", width: 100, prop: "taskCallOverdue" },
        { label: "首次跟进时间", width: 150, prop: "firstFollowTime" },
        { label: "最近跟进时间", width: 150, prop: "lastFollowTime" },
        { label: "下次跟进时间", width: 150, prop: "nextFollowTime" },
        { label: "最近到店时间", width: 150, prop: "firstArrivalTime" },
        { label: "创建时间", width: 150, prop: "ctime" },
        { label: "邀约次数", width: 80, prop: "invitationTimes" },
        { label: "到店次数", width: 80, prop: "storeTimes" },
        { label: "试驾次数", width: 80, prop: "driveTimes" },
        { label: "唯一线索id", width: 180, prop: "clueId" },
        { label: "ERP", width: 100, prop: "erp" },
      ],
      userInfoDrawer: false, //用户信息抽屉状态
      userDetailData: {},    //客户详情
      userInfoLisy: [        //客户概况
        { key: "clueId", value: "", label: "唯一线索" },
        { key: "erp", value: "", label: "ERP" },
        { key: "customerMobile", value: "", label: "手机号" },
        { key: "customerName", value: "", label: "客户姓名" },
        { key: "gender", value: "", label: "性别" },
        { key: "customerType", value: "", label: "客户种类" },
        { key: "provinceName", value: "", label: "省" },
        { key: "ctiy", value: "", label: "市" },
        { key: "county", value: "", label: "区/县" },
        { key: "address", value: "", label: "详细地址" },
        { key: "email", value: "", label: "邮箱地址" },
        { key: "wxBindTime", value: "", label: "好友绑定时间" },
        { key: "wxName", value: "", label: "微信好友" },
        { key: "callCheck", value: "", label: "呼叫中心核验状态" },
        { key: "callCheckResult", value: "", label: "呼叫中心核验结果" },
        { key: "seller", value: "", label: "销售顾问" },
        { key: "sourceNum", value: "", label: "渠道来源次数" },
        { key: "smartNo", value: "", label: "智慧号" },
        { key: "liveRoomId", value: "", label: "直播间id" },
        { key: "channelCueId", value: "", label: "源线索ID" },
        { key: "channelOrderId", value: "", label: "源订单编号" },
        { key: "channelReDealerId", value: "", label: "源经销商id" },
        { key: "channelActionType", value: "", label: "互动类型" },
        { key: "channelClueSource", value: "", label: "源线索来源" },
        { key: "ownerDepart", value: "", label: "部门归属" },
        { key: "channelClueType", value: "", label: "渠道类型" },
        { key: "fixedTelephone", value: "", label: "固定电话" },
      ],
      clientItineraryList: {},    //客户旅程数据
      clueListData: [       //线索列表数据
        { label: "归属经销商", width: 100, prop: "dealer" },
        { label: "下发时间", width: 220, prop: "receiveAllotTime" },
        { label: "手机号", width: 100, prop: "customerMobile" },
        { label: "渠道", width: 220, prop: "channelName" },
        { label: "意向车型", width: 220, prop: "seriesName" },
        { label: "意向产品", width: 220, prop: "modelName" },
        { label: "姓名", width: 100, prop: "customerName" },
        { label: "性别", width: 100, prop: "gender" },
        { label: "省份", width: 100, prop: "province" },
        { label: "城市", width: 100, prop: "city" },
        { label: "区县", width: 100, prop: "county" },
        { label: "线索编号/ID", width: 180, prop: "clueId" },
      ],
      flowListData: [       //客流列表数据
        { label: "创建时间", width: 180, prop: "ctime" },
        { label: "客户姓名", width: 130, prop: "customerName" },
        { label: "手机号", width: 120, prop: "customerMobile" },
        { label: "进店类型", width: 100, prop: "entryTypeName" },
        { label: "进店目的", width: 100, prop: "entryPurposeName" },
        { label: "进店时间", width: 180, prop: "entryTime" },
        { label: "进店人数", width: 100, prop: "entryPeopleNum" },
        { label: "备注", width: 100, prop: "remark" },
        { label: "归属员工", width: 120, prop: "creator" },
        { label: "客流状态", width: 100, prop: "stateName" },
        { label: "离店时间", width: 180, prop: "leaveTime" },
        { label: "创建人", width: 100, prop: "creator" },
        { label: "创建人部门", width: 230, prop: "createDept" },
      ],
      driveListData: [      //试乘试驾列表数据
        { label: "创建时间", width: 180, prop: "ctime" },
        { label: "试驾编号", width: 130, prop: "driveCode" },
        { label: "试驾人姓名", width: 130, prop: "driver" },
        { label: "试驾人手机号", width: 100, prop: "driverMobile" },
        { label: "试驾车型", width: 220, prop: "modeName" },
        { label: "评价状态", width: 100, prop: "evaluateStateName" },
        { label: "创建人", width: 130, prop: "creator" },
      ],
      orderListData: [      //订单列表数据
        { label: "订单状态", width: 100, prop: "orderStateStr" },
        { label: "创建时间", width: 220, prop: "ctime" },
        { label: "订单来源", width: 100, prop: "orderSourceStr" },
        { label: "spu名称/下订车型", width: 180, prop: "spuName" },
        { label: "数量", width: 100, prop: "skuNum" },
      ],
      clueList: [],
      flowList: [],
      driveList: [],
      orderList: [],
      orderDetail: {      //订单详情
        invoice: {},
        order: {}
      },
      orderDialogVisible: false,    //订单详情弹窗状态
      orderTabSelect: "order",      //订单弹窗tab
      itineraryPageNum: 1,          //客户旅程当前页码
      itineraryPage: 0,             //客户旅程总页数
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    emptyToLine,
    // 页面列表翻页
    pageHandler(params, num) {
      // this.listLoading = true;
      // postBazaarList({
      //   ...this.searchData,
      //   page: num ? num : params.pageNum,
      //   size: params.pageSize
      // }).then(res => {
      //   this.contarctData = res.body.data;
      //   params.render(res.body.total);
      //   this.listLoading = false;
      // });

      // 获取用户列表
      this.getClientList(params, num ? num : params.pageNum, params.pageSize);
      // 统计用户列表数量
      // this.getClientStageCount();
      // 获取客户旅程列表
      // this.getClientItineraryList();
    },
    // 重置搜索数据
    resetSearchData(e) {
      this.searchData = {
        customerName: "",     //客户姓名
        customerMobile: "",   //客户电话
      }
    },
    operBtnHandle(){
      this.operBtnFlag = !this.operBtnFlag
      if(this.operBtnFlag){
        this.operBtnText = '收起'
        this.operBtnClas = 'el-icon-arrow-up el-icon--up'
      }else{
        this.operBtnText = '展开'
        this.operBtnClas = 'el-icon-arrow-down el-icon--down'
      }

    },
    // 重构模版字符串
    resetTemplateFn(ele){
      return ele.extra?JSON.parse(ele.extra).remark:ele.wayContent;
    },
    // 点击客户展开详细信息
    openUserInfoFn(row) {
      this.selectTab = "1"; //标签已选数据
      this.selectMainTab = "1"; //客户信息标签选择
      this.groupBtnData = "0"; //旅程自定义tabs当前选中
      this.clueList = [];
      this.flowList = [];
      this.driveList = [];
      this.orderList = [];
      this.userInfoDrawer = true;
      this.itineraryPageNum = 1;          //客户旅程当前页码
      this.itineraryPage = 0;             //客户旅程总页数
      this.clientItineraryList = {};      //客户旅程数据
      // 获取客户客户详情
      this.getClientDetail(row.clueId||"")
      // 获取客户旅程列表
      this.getClientItineraryList(row.clueId||"");
    },
    // 客户信息tab点击
    maintabHandleClick(e){
      this.selectMainTab = e.name;
      switch (e.name) {
        case "1":
          this.itineraryPageNum = 1;          //客户旅程当前页码
          this.itineraryPage = 0;             //客户旅程总页数
          this.clientItineraryList = {};      //客户旅程数据
          // 获取客户旅程列表
          this.getClientItineraryList();
          break;
        case "2":
          // 获取客户线索列表
          this.getClientClueList();
          break;
        case "3":
          // 获取客户客流列表
          this.getClientFlowList();
          break;
        case "4":
          // 获取客户试驾列表
          this.getClientDriveList();
          break;
        case "5":
          // 获取客户订单列表
          this.getClientOrderList();
          break;

        default:
          break;
      }
    },
    // 自定义button tabs点击
    groupBtnFn(e){
      this.itineraryPageNum = 1;          //客户旅程当前页码
      this.itineraryPage = 0;             //客户旅程总页数
      this.clientItineraryList = {};      //客户旅程数据
      this.groupBtnData = e;
      // 获取客户旅程列表
      this.getClientItineraryList();
    },
    // 自定义button tabs点击 下拉点击
    groupBtnDropdown(e){
      this.itineraryPageNum = 1;          //客户旅程当前页码
      this.itineraryPage = 0;             //客户旅程总页数
      this.clientItineraryList = {};      //客户旅程数据
      this.groupBtnData = e;
      // 获取客户旅程列表
      this.getClientItineraryList();
    },
    // 订单列表操作
    orderHandleFn(row){
      this.orderDetail.order = row;
      this.orderDialogVisible = true;
      // 获取客户订单发票
      this.getClientOrderInvoice(row.orderId);
    },
    // 订单详情弹窗操作
    orderDialogChange(){
      this.orderTabSelect = 'order';
      this.orderDialogVisible = !this.orderDialogVisible;
      if(this.orderDialogVisible){
        this.orderDetail = {
          invoice: {},
          order: {}
        }
      }
    },
    // 客户旅程滚动监听
    clientItineraryScroll(e) {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      const isBottom = scrollTop + clientHeight >= scrollHeight-5;
      let itineraryPage = this.itineraryPage;
      let itineraryPageNum = this.itineraryPageNum;
      if(isBottom){
        itineraryPageNum++;
        if(itineraryPageNum<=itineraryPage){
          this.itineraryPageNum = itineraryPageNum;
          // 获取客户旅程列表
          this.getClientItineraryList();
        }
      }
    },







    /**
     * 接口方法
     */
    // 更新数据
    updateUserFn(){
      this.$store.dispatch('newCarUser/getClientUpdate',{}).then(res => {
        this.$message.success('更新任务后台运行中，请稍后查看')
      })
    },
    // 获取用户列表
    getClientList(params,pageNum,pageSize) {
      this.listLoading = true;
      this.$store.dispatch('newCarUser/getClientList',{
        ...this.searchData,
        stage: this.selectTab,
        pageNum,
        pageSize
      }).then(res => {
        if(res.code == 0){
          this.listData = res.data.list;
          params.render(res.data.total);
          this.customerTotal = res.data.total;
        }
        this.listLoading = false;
      })
    },
    // 统计用户列表数量
    getClientStageCount() {
      this.$store.dispatch('newCarUser/getClientStageCount',{}).then(res => {
        if(res.code == 0){
          this.tabList = res.data;
        }
      })
    },
    // 获取客户线索列表
    getClientClueList(customerMobile=this.userDetailData.customerMobile) {
      this.detailListLoading = true;
      this.$store.dispatch('newCarUser/getClientClueList',{
        customerMobile
      }).then(res => {
        if(res.code == 0){
          this.clueList = res.data || [];
        }
        this.detailListLoading = false;
      })
    },
    // 获取唯一线索详情
    getClientDetail(clueId) {
      this.listLoading = true;
      this.$store.dispatch('newCarUser/getClientDetail',{
        onlyClueId:clueId
      }).then(res => {
        if(res.code == 0){
          this.userDetailData = res.data
        }
        this.listLoading = false;
      })
    },
    // 获取客户试驾列表
    getClientDriveList(customerMobile=this.userDetailData.customerMobile) {
      this.detailListLoading = true;
      this.$store.dispatch('newCarUser/getClientDriveList',{
        customerMobile
      }).then(res => {
        if(res.code == 0){
          this.driveList = res.data || [];
        }
        this.detailListLoading = false;
      })
    },
    // 获取客户客流列表
    getClientFlowList(customerMobile=this.userDetailData.customerMobile) {
      this.detailListLoading = true;
      this.$store.dispatch('newCarUser/getClientFlowList',{
        customerMobile
      }).then(res => {
        if(res.code == 0){
          this.flowList = res.data || [];
        }
        this.detailListLoading = false;
      })
    },
    // 获取客户旅程列表
    getClientItineraryList(clueId=this.userDetailData.clueId) {
      this.detailListLoading = true;
      this.$store.dispatch('newCarUser/getClientItineraryList',{
        clueId,
        pageNum: this.itineraryPageNum,
        pageSize: 10,
        type: this.groupBtnData
      }).then(res => {
        if(res.code == 0){
          this.clientItineraryList= res.data.list
          this.clientItineraryList.forEach(item =>{
            item.wayContent  = item.wayContent
              .replaceAll('<!n*>','</br>')
              .replaceAll('<!]*>','&nbsp;:&nbsp;&nbsp;&nbsp;');
          })
        }
        this.detailListLoading = false;
      })
    },
    // 获取客户订单发票
    getClientOrderInvoice(orderId) {
      this.listLoading = true;
      this.$store.dispatch('newCarUser/getClientOrderInvoice',{
        orderId
      }).then(res => {
        if(res.code == 0){
          this.orderDetail.invoice = res.data;
        }
        this.listLoading = false;
      })
    },
    // 获取客户订单列表
    getClientOrderList(customerMobile=this.userDetailData.customerMobile) {
      this.detailListLoading = true;
      this.$store.dispatch('newCarUser/getClientOrderList',{
        customerMobile,
        pageNum: 1,
        pageSize: 99,
      }).then(res => {
        if(res.code == 0){
          this.orderList = res.data.list || [];
        }
        this.detailListLoading = false;
      })
    },

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
  //生命周期 - 创建之前
  beforeCreate() {},
  //生命周期 - 挂载之前
  beforeMount() {},
  //生命周期 - 更新之前
  beforeUpdate() {},
  //生命周期 - 更新之后
  updated() {},
  //生命周期 - 销毁之前
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {},
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {}
};
</script>
<style lang="scss" scoped>
//@import url(); 引入公共css类
::v-deep .el-drawer{
  min-width: 1100px !important;
}
.page_box {
  padding: 0 20px;
  .search_box {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
  }
  .table_box_custom {
    display: flex;
    align-items: center;
    cursor: pointer;
    .default_head {
      width: 60px;
      height: 60px;
      display: block;
      border-radius: 100%;
      margin-right: 10px;
    }
    .user_name {
      > div:nth-child(1) {
        color: #0083ff;
        font-size: 14px;
      }
    }
  }
  .table_top_box {
    background: #fff;
    display: flex;
    align-items: center;
    padding: 15px 20px;
    margin-bottom: -20px;
    box-sizing: border-box;
    .table_top_handle {
      margin-left: auto;
    }
  }
  .container_box {
    background: #f1f5f7;
    height: 100%;
    padding: 25px;
    box-sizing: border-box;
    .container_box_head {
      .label {
        color: #74788d;
        line-height: 30px;
        font-size: 13px;
      }

      .value {
        color: #262626;
        font-size: 13px;
        font-weight: 400;
        line-height: 30px;
      }
      background: #fff;
      margin-bottom: 25px;
      box-shadow: 4px 6px 20px 0 rgba(0,0,0,.02);
      padding: 20px;
      box-sizing: border-box;
      height: auto;
      .head_01{
        display: flex;
        align-items: center;
        justify-content: space-between;
        .head_01_left{
          display: flex;
          align-items: center;
          .left_img{
            width: 70px;
            height: 70px;
            border-radius: 12px;
            margin-right: 20px;
            img{
              width: 100%;
              height: 100%;
              display: block;
            }
          }
          .left_info{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 70px;
            .left_info_top{
              display: flex;
              align-items: center;
              >div{
                margin-right: 15px;
                font-size: 16px;
                font-weight: bold;
              }
              >i{
                margin-right: 10px;
                font-size: 18px;
                color: #d2d2d2;
                font-weight: bold;
              }
            }
            .left_info_down{
              display: flex;
              align-items: center;
              margin-bottom: 10px;
              cursor: pointer;
              >div{
                margin-right: 15px;
                font-size: 12px;
                color: #74788d;
                text{
                  color: #409eff;
                }
              }
            }
          }
        }
      }
      .head_02{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 25px 0;
        .head_02_right,.head_02_left{
          width: 50%;
        }
        .head_02_left{
          border-right: 1px solid #e7e7e7;
          box-sizing: border-box;
          .left_item{
            display: flex;
            align-items: center;
            .left_people{
              display: flex;
              align-items: center;
              margin-right: 30px;
              .left_item_people{
                display: flex;
                align-items: center;
                font-size: 12px;
                border: 1px solid #dcdcdc;
                border-radius: 3px;
                padding: 4px 5px;
                img{
                  width: 18px;
                  height: 18px;
                  border-radius: 100%;
                  margin-right: 5px;
                }
              }
            }
            .left_item_btn{
              display: flex;
              align-items: center;
            }
          }
        }
        .head_02_right{
          padding-left: 25px;
          .right_item{
            display: flex;
            align-items: center;
            justify-content: space-between;
            >div{
              display: flex;
              align-items: center;
            }
            .tag_box{
              overflow: hidden;
              width: 300px;
              white-space: nowrap;
              .el-tag{
                margin-right: 5px;
              }
            }
            .tag_hint{
              color: #999;
              font-size: 14px;
            }
          }
        }
      }
      .head_03{
        display: flex;
        align-items: center;
        font-size: 12px;
        >div{
          margin-right: 20px;
        }
      }
    }
    .container_box_aside {
      background: #fff;
      margin-right: 20px;
      box-shadow: 4px 6px 20px 0 rgba(0,0,0,.02);
      padding: 20px;
      box-sizing: border-box;
      overflow: scroll;
      max-height: 644px;
      .container_box_aside_head{
        display: flex;
        margin-bottom: 10px;
        i{
          margin-top: 3px;
          margin-right: 5px;
        }
        .container_box_aside_title{
          font-size: 14px;
          font-weight: bold;
          color: #333;
          >div:nth-child(2){
            color: rgba(80, 93, 105, .6);
            font-size: 13px;
            margin-top: 5px;
          }
        }
      }
      .container_box_aside_item{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 5px;
        box-sizing: border-box;
        font-size: 13px;
        >div:first-child{
          color: #74788d;
          width: 110px;
          flex-shrink: 0;
        }
        >div:last-child{
          flex: 1;
          color: #505d69;
          text-align: end;
        }
      }
    }
    .container_box_main {
      background: #fff;
      box-shadow: 4px 6px 20px 0 rgba(0,0,0,.02);
      padding: 0 !important;
      .container_box_main_tabs{
        margin-left: -1px;
      }
      .container_box_main_content{
        padding: 0 20px 20px 20px;
        box-sizing: border-box;
        .button_group{
          width: 100%;
          overflow: scroll;
          display: flex;
          align-items: center;
          >.button_group_item{
            margin: 0 !important;
            margin-right: -1px !important;
          }
          >.button_group_item:first-child{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
          }
          >.button_group_item:last-child{
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }
        .button_group::-webkit-scrollbar {
          display: none;
        }
      }
      // 客户旅程
      .client_itinerary{
        padding: 10px 50px;
        box-sizing: border-box;
        max-height: 520px;
        overflow-y: scroll;
        .client_itinerary_title{
          font-size: 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          >div:nth-child(2){
            font-size: 13px;
            color: #1883ff;
            border: 1px solid #1883ff;
            border-radius: 3px;
            box-sizing: border-box;
            padding: 0 5px;
          }
        }
        .client_itinerary_dot{
          margin-left: -19px;
          margin-top: 8px;
          background: #fff;
          padding: 10px 0;
          text-align: center;
          font-size: 12px;
          color: #74788d;
          white-space: pre-line;
          .client_itinerary_dot_icon{
            width: 40px;
            height: 40px;
            display: block;
            margin-bottom: 5px;
          }
          .client_itinerary_dot_circle{
            width: 11px;
            height: 11px;
            padding-top: 30px;
            position: absolute;
            top: -30px;
            left: -5px;
            background: #fff;
            .circle{
              width: 11px;
              height: 11px;
              border-radius: 100%;
              border: 1px solid #adb5bd;
              background: #f8f9fa;
              box-sizing: border-box;
            }
          }
        }
        .client_itinerary_dot_first{
          margin-top: 23px !important;
        }
        .client_itinerary_dot_html{
          white-space: pre-wrap !important;
          background: #f8f9fa;
          font-size: 13px;
          line-height: 20px;
          padding: 12px;
          box-sizing: border-box;
          margin: 10px 0;
          color: #505d69;
        }
        .client_itinerary_dot_operator{
          display: flex;
          align-items: center;
          font-size: 13px;
          img{
            width: 28px;
            height: 28px;
            display: block;
            border-radius: 100%;
            margin-right: 10px;
          }
          >div:nth-child(2){
            margin-right: 20px;
            color: #505d69;
          }
          >div:nth-child(3){
            color: #adb5bd;
          }
        }
      }
      // 客户旅程外另四个列表
      .table_model_box{
        padding: 0 15px;
        box-sizing: border-box;
      }
    }
  }
}
::v-deep .el-dialog__body{
  padding-top: 0 !important;
}
::v-deep .el-descriptions__header{
  margin: 10px 0px !important;
}
::v-deep .el-descriptions__title{
  font-size: 13px !important;
}
::v-deep .el-descriptions__body{
  padding: 0 20px !important;
}
::v-deep .el-timeline-item__tail{
  border-left: 1px solid #dcdee0 !important;
  left: 0 !important;
}
::v-deep .el-timeline .el-timeline-item:last-child .el-timeline-item__tail{
  display: initial !important;
}
::v-deep .el-timeline:last-child .el-timeline-item:last-child .el-timeline-item__tail{
  display: none !important;
}
::v-deep .el-timeline{
  margin-bottom: 30px !important;
}
::v-deep .el-timeline-item__timestamp{
  font-size: 16px !important;
  color: #505d69 !important;
  font-weight: bold;
  margin-bottom: 15px;
}
::v-deep .el-timeline-item__wrapper{
  padding-left: 50px !important;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav{
  border: none;
  border-radius: 0;
  box-sizing: border-box;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item{
  border-left: 1px solid transparent;
  border-right: 1px solid transparent;
  border-top: 3px solid transparent;
  box-sizing: border-box;
}
::v-deep .el-tabs--card > .el-tabs__header .el-tabs__item.is-active{
  border-left: 1px solid #dcdee0;
  border-right: 1px solid #dcdee0;
  border-top: 3px solid #2278f5;
  box-sizing: border-box;
}
::v-deep .el-input-group__prepend {
  background-color: #fff !important;
}
::v-deep .el-table__header thead tr th:first-of-type {
  padding-left: 0;
}
.el-select {
  width: 110px !important;
}
</style>
